<template>
  <span class="st-label" data-t="st-label">
    <slot />
    {{ props.label }}
  </span>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    label?: string | number
    bgColor?: string
    size?: 's' | 'xs'
  }>(),
  {
    label: '',
    bgColor: 'orange',
    size: 's',
  },
)
</script>

<style scoped>
.st-label {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: var(--spacing-025) var(--spacing-075);

  font: var(--desktop-text-xs-medium);

  background: v-bind(bgColor);
  border-radius: var(--border-radius-075);
  box-shadow:
    0 4px 8px -4px rgb(0 0 0 / 24%),
    0 8px 16px -4px rgb(0 0 0 / 16%);
}
</style>
